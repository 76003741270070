import React from 'react';

import { NavLink } from 'react-router-dom';

const Navbar = React.memo(
    ()=>
        <div data-uk-sticky="sel-target: #root; cls-active: uk-navbar-sticky">
            <nav className="uk-navbar-container navbar-background">
                <div className="uk-container uk-container-small">
                    <div data-uk-navbar>
                    
                        <div className="uk-navbar-left">
                            <h1 className="uk-margin-remove">
                                <NavLink className="uk-navbar-item uk-logo uk-text-bold bg-white" to="/" aria-label="Back to Home">
                                    <img src={process.env.PUBLIC_URL + '/images/favicon.svg'} style={{height: '2em'}} alt="icon" />
                                    八雲舎
                                </NavLink>
                            </h1>
                        </div>

                        <div className="uk-navbar-right">
                            <a className="uk-navbar-toggle uk-navbar-toggle-animate" data-uk-navbar-toggle-icon href="#none"> </a>
                            <div className="uk-navbar-dropdown">
                                <ul className="uk-nav uk-navbar-dropdown-nav uk-text-large">
                                    <li><NavLink to="/">home</NavLink></li>
                                    <li><NavLink to="/about">about</NavLink></li>
                                    <li><NavLink to="/work">work</NavLink></li>
                                    <li><NavLink to="/link">link</NavLink></li>
                                    <li><NavLink to="/contact">contact</NavLink></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        </div>
);

export default Navbar;

