import { Link } from 'react-router-dom';

const Contact = () =>
    <>
        <div className="uk-text-center uk-margin-large-bottom">
            <img className="sub-logo" src={ process.env.PUBLIC_URL + '/images/contact.png' } alt="abstruction of Link page."  />
        </div>
        
        <div className="uk-flex uk-flex-center uk-margin-large-bottom">
            <h2 className="uk-heading-medium">Contact</h2>
        </div>

        <div className="docs">
            <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                <div>
                    <h3>e-mail:</h3>
                    <a
                        className="uk-button uk-button-default"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSf3QHtO3Hj96gTIEgKWo1NcnvxDnR7Z0kAVTCabehDNJosaOw/viewform?usp=sf_link"
                        target="_blank"
                        rel="noreferrer">
                        mail form
                    </a>
                </div>
                <div>
                    <h3>social:</h3>
                    <a
                        className="uk-button uk-button-default"
                        href="https://twitter.com/Yakumosya"
                        target="_blank"
                        rel="noreferrer"
                        data-uk-icon="icon: twitter"> </a>
                </div>
            </div>
        </div>

        <div className="uk-text-center">
            <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/">home</Link>
        </div>
    </>
;

export default Contact;
