import { Link } from 'react-router-dom';

const Work = () =>
    <>
        <div className="uk-text-center uk-margin-large-bottom">
            <img className="sub-logo" src={ process.env.PUBLIC_URL + '/images/work.png' } alt="abstruction of Work page."  />
        </div>
        
        <div className="uk-flex uk-flex-center uk-margin-large-bottom">
            <h2 className="uk-heading-medium">Work</h2>
        </div>
        <div className="uk-text-center uk-margin-large-bottom">
            <h3 className="uk-heading-small">画像編集</h3>
            <img src={ process.env.PUBLIC_URL + '/images/edited-girl.png' } alt="edited pthoto" className="circle theme-border" />
        </div>

        <div className="docs">
            <ul className="uk-list uk-list-disc">
                <li>明るさ・色調の変更</li>
                <li>部分的な修正</li>
                <li>その他調整</li>
            </ul>
        </div>

        <div className="uk-text-center">
            <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/">home</Link>
        </div>
    </>
;

export default Work;
