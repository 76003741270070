import { Link } from 'react-router-dom';

const NotFound = ()=>
    <div className="uk-flex uk-flex-center uk-margin-large-bottom">
        <div className="uk-alert-warning uk-padding" data-uk-alert>
            <h2 className="">not found: {document.URL}</h2>
        </div>

        <div className="uk-text-center">
            <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/">home</Link>
        </div>

    </div>
;

export default NotFound;
