import React from 'react';
import { Link } from 'react-router-dom';

const Footer = React.memo(
    ()=>
        <footer className="uk-margin-large-top uk-padding uk-background-muted">
            <div className="uk-container uk-container-small">
                <div className="uk-child-width-1-1 uk-child-width-1-3@s" data-uk-grid>

                    <div>
                        <ul className="uk-list uk-list-divider">
                            <li><Link className="uk-link-text" to="/">八雲舎</Link>：</li>
                            <li>
                                <ul className="uk-list uk-margin-left">
                                    <li></li>
                                    <li><Link className="uk-link-text" to="/about">about</Link></li>
                                    <li><Link className="uk-link-text" to="/work">work</Link></li>
                                    <li><Link className="uk-link-text" to="/link">link</Link></li>
                                    <li><Link className="uk-link-text" to="/contact">contact</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <ul className="uk-list uk-list-divider">
                            <li>link：</li>
                            <li>
                                <ul className="uk-list uk-margin-left">
                                    <li></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <ul className="uk-list uk-list-divider">
                            <li>etc:</li>
                            <li>
                                <a href="https://twitter.com/Yakumosya" target="_blank" rel="noreferrer" uk-icon="icon: twitter"> </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <p className="uk-text-muted uk-text-small uk-text-center"> &copy; 2017- yakumosya.com</p>
        </footer>
);

export default Footer;
