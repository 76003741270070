import { Link } from 'react-router-dom';

const Home = () =>
    <>
        <div className="main-logo uk-flex uk-flex-center uk-margin-bottom">
            <div className="tategaki">
                八雲舎
            </div>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-large-bottom">
            <div className="uk-text-default serif">Yakumosya</div>
        </div>
        <div className="uk-flex uk-flex-center uk-child-width-1-4@m uk-text-center uk-margin-large-bottom" data-uk-grid>
            <h3>
                <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/about">about</Link>
            </h3>
            <h3>
                <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/work">work</Link>
            </h3>
            <h3>
                <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/link">link</Link>
            </h3>
            <h3>
                <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/contact">contact</Link>
            </h3>
        </div>
        <div className="uk-margin-bottom uk-text-center">
            <img src={ process.env.PUBLIC_URL + '/images/top.png' } className="sub-logo" alt="八雲" />
        </div>
        <div className="uk-flex uk-flex-center uk-margin-large-bottom">
            <h2>NEWS</h2>
        </div>
        <div className="docs uk-text-small">
            <ul className="uk-list">
                <li>2023.04.06 : サイトリニューアル</li>
            </ul>
        </div>
    </>
;

export default Home;
