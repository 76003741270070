import { Link } from 'react-router-dom';

const Links = () =>
    <>
        <div className="uk-text-center uk-margin-large-bottom">
            <img className="sub-logo" src={ process.env.PUBLIC_URL + '/images/link.png' } alt="abstruction of Link page."  />
        </div>
        
        <div className="uk-flex uk-flex-center uk-margin-large-bottom">
            <h2 className="uk-heading-medium">Link</h2>
        </div>
        
        <div className="docs">
            作成中
        </div>

        <div className="uk-text-center">
            <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/">home</Link>
        </div>
    </>
;

export default Links;
