import { Outlet, Route, Routes } from 'react-router-dom';

import Navbar from './Navbar'
import Footer from './Footer';

import Home from './pages/Home'
import About from './pages/About'
import Work from './pages/Work'
import Links from './pages/Links'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound';


const App = ()=>
  <Routes>
    <Route path="/" element={ <AppLayout /> }>
        <Route index element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/work" element={ <Work />} />
        <Route path="/link" element={ <Links /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="*" element={ <NotFound /> } />
    </Route>
  </Routes>
;

const AppLayout = ()=>
  <>
    <Navbar />
    <div className="uk-container uk-container-small uk-margin-large">
      <Outlet />
    </div>
    <Footer />
  </>
;


export default App;
