import { Link } from 'react-router-dom';

const About = () =>
    <>
        <div className="uk-text-center uk-margin-large-bottom">
            <img className="sub-logo" src={ process.env.PUBLIC_URL + '/images/about.png' } alt="abstruction of About page."  />
        </div>
        
        <div className="uk-flex uk-flex-center uk-margin-large-bottom">
            <h2 className="uk-heading-medium">About</h2>
        </div>

        <div className="docs">
            <p>
                当サイトは八雲舎（やくもしゃ）の業務内容紹介目的、
                作業依頼の連絡先、
                製作物の公開拠点として公開しています。
            </p>
            <p>
                主な業務内容は以下。
            </p>
            <ul className="uk-list uk-list-circle ">
                <li><span className="uk-display-inline-block uk-padding-left">ウェブサイト製作</span></li>
                <li><span className="uk-display-inline-block uk-padding-left">画像編集</span></li>
                <li><span className="uk-display-inline-block uk-padding-left">その他コンテンツ製作</span></li>
            </ul>
            <p className="uk-text-right uk-margin-large-right">
                八雲舎
            </p>
        </div>

        <div className="uk-text-center">
            <Link className="uk-link-heading uk-button uk-button-default uk-border-rounded" to="/">home</Link>
        </div>
    </>
;

export default About;
